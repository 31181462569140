<template>
  <div v-if="item" class="ml-2 mr-1">
  <b-card>
    <b-col>
      适用法律：
      <b-badge
        v-for="(lawItem, lawIdx) in aryCompliance"
        :key="`lay-${lawIdx}`"
        variant="light-primary"
        class="mr-1"
      >
        <b-icon-shield-lock /> <span>{{ lawItem }}</span>
      </b-badge>
    </b-col>

    <b-col class="my-1"> 编号：{{ item.issueNo }} </b-col>

    <b-col class="my-1"> 主题：{{ item.title }} </b-col>

    <b-col class="my-1"> 概述：{{ item.abstract }} </b-col>

    <b-col class="my-1" v-if="item.displayFile">
      <pdf
        v-for="i in numPages"
        :src="item.displayFile"
        :page="i"
        :key="i"
        class="d-inline-block w-100"
      />
      <!-- <object
        :data="item.displayFile"
        type="application/pdf"
        width="100%"
        height="600px"
      >
        <p>
          Alternative text - include a link
          <a :href="item.displayFile">to the PDF!</a>
        </p>
      </object> -->
    </b-col>
  </b-card>
  </div>
</template>

<script>
import {
  BBadge,
  BCard,
  BIconShieldLock,
  BRow,
  BCol,
  BFormGroup,
  BFormCheckboxGroup,
  BFormInput,
  BFormTextarea,
  BFormFile,
  BLink,
  BProgress,
} from "bootstrap-vue";
import pdf from "vue-pdf";

export default {
  name: "OrganizationView",

  components: {
    BBadge,
    BCard,
    BIconShieldLock,
    BCol,
    BFormGroup,
    BFormCheckboxGroup,
    BFormInput,
    BFormTextarea,
    BFormFile,
    BRow,
    BLink,
    BProgress,
    pdf,
  },

  data() {
    return {
      item: null,
      aryCompliance: [],

      numPages: 0,
      loadingPdfTask: null,
    };
  },

  beforeCreate() {
    let { id } = this.$route.params;
    this.$http.get("/privacyteams/" + id).then((res) => {
      this.item = res.data.data;
      this.aryCompliance = this.item.compliance
        ? this.item.compliance.split(",")
        : [];

      if (this.item.displayFile) {
        this.loadingPdfTask = pdf.createLoadingTask(this.item.displayFile);
        this.loadingPdfTask.promise.then((pdf) => {
          this.numPages = pdf.numPages;
        });
      }
    });
  },

  computed: {},

  methods: {},
};
</script>

<style>
</style>